import configuration from "../../configuration";
import { clearCookie, setCookie } from "../_helper";
import { getLang } from ".";

import "./languages.css";

export const initLanguageSwitcher = () => {
	if(configuration.languages.length <= 1){
		clearCookie("LANG");
		return;
	}

	/*
	* this is only running once on load in preview version
	*/
	const setLanguageButton = (lang:string) => {
		if (!lang) {return;}
		setCookie("LANG", lang);
	};

	const hrLang = (l:string) => {
		switch(l.toLowerCase()){
		case "nl": return "Nederlands";
		case "en": return "English";
		case "fr": return "Français";
		case "de": return "Deutsch";
		default: return l;
		}
	};

	const toggleLanguagePanel = () => {
		let langPanel = document.querySelector<HTMLElement>("aside#language-panel");
		if(!langPanel){
			langPanel = document.createElement("aside");
			langPanel.id = "language-panel";
			const curLang = getLang();
			langPanel.innerHTML = configuration.languages
				.map(l => `<lang-switch ${l !== curLang ? "" : 'class="active"'} target-lang="${l}">${hrLang(l)}</lang-switch>`)
				.join("");
			document.body.append(langPanel);

			const langSwitches = Array.from(langPanel.querySelectorAll<HTMLElement>("lang-switch"));
			langSwitches.forEach(btn=>{
				const lang = btn.getAttribute("target-lang") || "de";
				btn.onclick = (e:Event) => {
					e.preventDefault();
					setLanguageButton(lang);
					location.reload();
				};
			});
		}

		langPanel.classList.toggle("active");
	};

	const langSwitch = document.querySelector<HTMLElement>("lang-container");
	if(!langSwitch){
		throw new Error("Can't find lang-container");
	}
	langSwitch.onclick = toggleLanguagePanel;

	const initLanguageButtons = () => {
		const language = getLang();
		if (!language) {return;}

		for(const btn of document.querySelectorAll<HTMLElement>("lang-container lang-switch")){
			btn.classList.remove("active");
			if (btn.getAttribute("lang") === language) {
				btn.classList.add("active");
			}
		}
	};
	initLanguageButtons();

	/*
	* This is only running in static production builds
	*/
	const setStaticLang = () => {
		const langAnchors = Array.from( document.querySelectorAll<HTMLAnchorElement>("lang-redir a"));
		if (!langAnchors) { return;}
		for (const langAnchor of langAnchors) {
			const anchorURL = new URL(langAnchor.href);
			const currentHost = window.location.host;
			if (anchorURL.host === currentHost) {
				langAnchor.classList.add("active");
			}
		}
	};
	setStaticLang();

	const isStatic = ():boolean => {
		const langAnchors = Array.from( document.querySelectorAll<HTMLAnchorElement>("lang-redir a"));
		return langAnchors.length > 0;
	};

	const setLang = (lang:string) => {
		if(isStatic()){
			/* Gotta redirect here, changing the first part of the address from /de/ -> /fr/ for example */
		} else {
			setCookie("LANG", lang);
		}
	};

	const getLangFromUrlParams = () => {
		const queryString:string = window.location.search;
		const urlParams:URLSearchParams = new URLSearchParams(queryString);
		if (!urlParams) { return "";}
		const langParam = urlParams.get("LANG");
		if (!langParam) { return "";}
		urlParams.delete("LANG");
		window.location.search = urlParams.toString().slice(0,-1);
		const ok = ["de","en","fr","nl"];
		if (!ok.includes(langParam.toLowerCase())) {return "";}
		return langParam.toLowerCase();
	};

	/* remove attribute on load and load the correct lang */
	const redirectedLang = getLangFromUrlParams();
	if (redirectedLang) {
		setLang(redirectedLang);
		return;
	}

	if (!document.documentElement.lang) {
		const prefLangArr = navigator.languages || [navigator.language];
		if (!prefLangArr) {
			setLang("de");
		}
		for (const prefLang of prefLangArr) {
			const langStr = prefLang.split("-")[0].trim();
			if (langStr === "de"|| langStr ==="fr" || langStr ==="nl") {
				setLang(langStr);
			}
		}
	}
};

setTimeout(initLanguageSwitcher, 0);
