import {showLoginModal} from "./session-management/login";

import "./login-lock.css";

export const initLoginLock = () => {
	// Get the lock status from the HTML Element
	const node = document.body.parentElement!;
	const lockStatus = node.getAttribute("lock-status") || "public";
	const shouldLock = !((lockStatus === "free") || (lockStatus === "unknown"));

	if(!shouldLock) {return;}

	window.addEventListener("ugm-logged-out", () => {
		showLoginModal(true);
	});
};


setTimeout(initLoginLock, 0);
