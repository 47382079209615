import { showEmbeddingSections } from "../embedding/embedding";
import { hideElementContentHandler, scrollToElement } from "../../_helper";
import { initSupportSlider } from "./support-slider";

import "./tab-box.css";

const initDefaultTabBox = (box:HTMLElement) => {
	const innerContent = box.querySelector<HTMLElement>("inner-content");
	if(!innerContent){return;}
	const tabHeader  = innerContent.querySelectorAll<HTMLElement>("tab-box-header");
	const tabContent = innerContent.querySelectorAll<HTMLElement>("tab-box-content");
	const tabSource  = innerContent.querySelectorAll<HTMLElement>("tab-box-source");
	const tabWrappers = innerContent.querySelectorAll<HTMLElement>("tab-box-header-wrap");

	showEmbeddingSections(innerContent.querySelector<HTMLElement>("tab-box-content.active"));

	const activateTwin = (tab:HTMLElement) => {
		const index = tab.getAttribute("tab-index");
		tabWrappers.forEach((wrapper => {
			for (let i = 0; i < wrapper.children.length; i++) {
				const tabTwin = wrapper.children[i];
				if (tabTwin.getAttribute("tab-index") === index) {
					tabTwin.classList.add("active");
				}
			}
		}));
	};

	tabHeader.forEach((header => {
		const curIndex = parseInt(header.getAttribute('tab-index') || "0");
		let curContent:HTMLElement | null = null;
		let curSource:HTMLElement | null = null;
		tabContent.forEach(cContent => {
			const curContentIndex = parseInt(cContent.getAttribute('tab-index') || "0");
			if(curIndex !== curContentIndex){return;}
			curContent = cContent;
		});
		tabSource.forEach(cSource => {
			const i = parseInt(cSource.getAttribute('tab-index') || "0");
			if(curIndex !== i){return;}
			curSource = cSource;
		});
		if(!curContent){return;}
		header.addEventListener('click', () => {
			tabHeader.forEach((cHeader)=>{cHeader.classList.remove('active');});
			tabContent.forEach((cContent)=>{cContent.classList.remove('active'); hideElementContentHandler(cContent);});
			tabSource.forEach((cSource)=>{cSource.classList.remove('active');});
			header.classList.add('active');
			curContent?.classList.add('active');
			if(curSource){
				curSource.classList.add('active');
			}
			showEmbeddingSections(curContent);
			activateTwin(header);
			scrollToElement(header, 64);
		});
	}));
};

const initTabBoxes = () => {
	document.querySelectorAll<HTMLElement>('section[content-type="tab-box"]').forEach(box => {
		const boxType = box.getAttribute('tab-box-type');
		switch(boxType){
		case "support":
			initSupportSlider(box);
			break;
		default:
			initDefaultTabBox(box);
			break;
		}
	});
};
setTimeout(initTabBoxes, 0);
