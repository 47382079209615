import "./license.css";

export const initRights = () => {
	document.querySelectorAll("details.license > summary").forEach(license => {
		const parent = license.parentElement;
		if(parent === null){return;}
		const licenseContent = parent.lastElementChild;
		if((licenseContent === null) || (licenseContent.innerHTML.trim().length === 0)){
			license.classList.add("missing-attribution");
		}
		parent.setAttribute("open","");
		license.addEventListener('click',(e) => {
			e.preventDefault();
			e.stopPropagation();
			parent.classList.toggle("active");
		});
	});
};
setTimeout(initRights, 0);
