const de = {
	mvet: {
		myMvet: "<b>Mein</b> mVet",
		goToShop: "Zum Shop",
		goToLibrary: "Zur Bibliothek",
		goToAccount: "Zum Account",
		yourOrders: "Ihre Bestellungen"
	},
	home: "Startseite",
	index: "Inhaltsverzeichnis",
	announcements: "Ankündigungen",
	delete: "Löschen",
	save: "Speichern",
	download: "Download",
	editTask: "Aufgabe bearbeiten",
	title: "Titel",
	addNote: "Notiz anlegen",
	files: "Dateien",
	perFile: "pro Datei",
	supportedFiles: "Unterstützte Dateiformate",
	login: "Anmelden",
	logout: "Abmelden",
	starlevel: "Auf welchem Level willst du das Kapitel bearbeiten?",
	lastVisitedPages: "Zuletzt besuchte Seiten:",
	author: "Urheber",
	upload: "Datei hochladen",
	uploadError: "Leider konnte die ausgew&auml;hlte Datei nicht gespeichert werden, eventuell ist Sie zu gro&szlig;.",
	saveCorrection: "Speichern",
	submitCorrection: "Abgeben",
	correctionSubmitted: "Abgegeben",
	correctionSubmittedModal: "Abgegeben",
	editLocked: "Du kannst den Text wieder bearbeiten, sobald deine Lehrkraft dir Rückmeldung gegeben hat.",
	//optout
	allowExternalEmbeds: "Erlaubnis, externe Inhalte zu laden",
	darkMode: "darkMode",
	page_view_history: "Seitenhistorie",
	LANG: "Spracheinstellung",
	local_only_ugm_notes: "Lokale Notizen",
	user_star_level: "globaler Schwierigkeitsgrad",
	star_data: "Gespeicherte Differenzierungen",
	local_only_ugm_correction: "lokal gespeicherte Eingaben Ihrer Übungen",
	//dynamic Pages
	glossary: "Glossareinträge (Sammlung)",
	//Contenttypes
	//embeds / privacy
	onlyAllowEmbedsForLoggedInUsers: "Um diesen interaktiven Inhalt zu nutzen loggen Sie sich bitte ein.",
	externalEmbed: "Externer Inhalt, hier klicken um fortzufahren",
	externalEmbedModal:`<p>An dieser Stelle finden Sie einen externen Inhalt. Wenn Sie solche Inhalte freischalten, können personenbezogene Daten an Drittplattformen übermittelt werden. </p><p>Mehr dazu in unserer <a href="./datenschutz/" target="_blank">Datenschutzerklaerung</a>.</p>`,
	accept: "Akzeptieren",
	cancel: "Abbrechen",
	privacyContainer: "Hier finden Sie ihre aktuell gespeicherten Einstellungen",
	//ugm login fields
	registerText: "Sie haben noch keine Zugänge für Ihre Klasse? Hier registrieren!",
	registerTextEnforced: "Sie haben noch keinen Zugang? Hier registrieren!",
	registerTextGeo: "Alle anderern Regionen: Sie haben noch keine Zugänge für Ihre Klasse(n)? Bitte hier registrieren!",
	userName: "Benutzername",
	password: "Passwort",
	loginInfoUsername: "Als <b>Lehrkraft</b> melden Sie sich mit Ihrer E-Mail-Adresse an.<br/><br/>Als <b>Schülerin oder Schüler</b> meldest du dich mit dem Kürzel deiner Lehrkraft im Format <b>“xxxx-farbe-tier”</b> an.",
	loginInfoUsername365: "<p>Ostbelgien: Nutzen Sie bitte den Button “Weiter mit Office 365” unten.</p><p>Alle übrigen Regionen der Euregio Maas-Rhein: </p><ul><li><p>Als Lehrperson melden Sie sich mit Ihrer Dienst-E-Mail  an. </p></li><li><p>Schülerinnen oder Schüler nutzen Ihr Kürzel im Format “xxxx-farbetier”.</p></li></ul><p></p>",
	loginInfoPassword: `Wenn du als Schülerin oder Schüler dein Passwort vergessen hast, wende dich bitte an deine Lehrkraft. Diese kann dein Passwort zurücksetzen und dir ein Übergangspasswort zum Ändern mitteilen.<br/><br/>
	Wenn Sie als Lehrkraft Ihr Passwort vergessen haben, nutzen Sie bitte den “Passwort vergessen”-Link in der Anmeldemaske. Ihnen wird anschließend ein Link per E-Mail zugeschickt, über welchen Sie ein neues Passwort erstellen können.`,
	loginInfoPasswordLink: "Passwort vergessen",
	loginInfoPassword365: (link:string) => `<p>Für Ostbelgien: Setzen Sie Ihr Passwort bitte ggf. über MS Office 365 zurück.&nbsp;</p>
		<p>Alle übrigen Regionen der Euregio Maas-Rhein:&nbsp;</p>
		<ul>
			<li>Wenn Sie als Lehrperson Ihr Passwort vergessen haben, klicken Sie bitte auf ${link}</li>
			<li>Wenn Sie als Schülerin oder Schüler Ihr Passwort vergessen haben, wenden Sie sich bitte an Ihre Lehrperson. Diese kann Ihr Passwort zurücksetzen und Ihnen ein temporäres Passwort mitteilen.</li>
		</ul>`,
	login365Label: "Weiter mit Office 365",
	login365Hint: "Hinweis: in Ostbelgien ist keine Registrierung nötig, klicken Sie direkt auf \"Weiter mit Office 365\".",
	
	invalidOIDCEmailTitle: "Ungültige E-Mail",
	invalidOIDCEmailBody: "Bitte verwenden Sie eine offizielle E-Mail-Adresse Ihrer Schule."
};
export default de;
