import './license-banner.css';
import { daysLeftInTrial, isTrialUser } from '../user-group-management';
import { showModal } from '../../modal/modal';

const showExpiredModal = () => {
	const html = `<div class="login-modal">
		<form>
			<h3>Ihr Testzugang ist abgelaufen.</h3>
			<br/>
			<p>Wenn Sie weiterhin das netBook Deutsch nutzen möchten, können Sie eine Lizenz erwerben.</p>
			<br/>
			<a href="/inhalt/netbook-bestellen" style="margin-left:0" class="license-banner__button">Lizenz erwerben</a>
		</form>
	</div>`;

	const modal = showModal(html, "centered-box", true);
};

const setupLicenseBanner = () => {
	window.addEventListener("ugm-logged-in", async () => {
		if(!await isTrialUser()){
			return;
		}
		if(document.querySelector("netbook-license-page")){
			return;
		}
		const remainingDays = await daysLeftInTrial();
		const daysLeftText = remainingDays > 0
			? `Sie können noch ${remainingDays} Tage testen.`
			: `Ihr Testzugang ist abgelaufen.`;

		if(remainingDays > 7){
			return;
		} else if(remainingDays < 0){
			const lockState = document.body.parentElement?.getAttribute("lock-status");
			if(lockState === "free" || lockState === "unknown"){
				return;
			}
			showExpiredModal();
			return;
		}

		const banner = document.createElement('div');
		banner.classList.add('license-banner');

		const infoText = document.createElement('p');
		infoText.innerHTML = `Schön, dass Sie sich für das netBook Deutsch interessieren.<br/>`;

		const remainingDaysContainer = document.createElement('div');
		remainingDaysContainer.classList.add('license-banner__remaining-days-container');

		const remainingDaysText = document.createElement('p');
		remainingDaysText.classList.add('license-banner__remaining-days');
		remainingDaysText.innerHTML = daysLeftText;
		remainingDaysContainer.appendChild(remainingDaysText);

		const buyLicenseButton = document.createElement('a');
		buyLicenseButton.classList.add('license-banner__button');
		buyLicenseButton.innerHTML = 'Lizenz erwerben';
		buyLicenseButton.href = window.location.origin + '/inhalt/netbook-bestellen';

		remainingDaysContainer.appendChild(remainingDaysText);
		remainingDaysContainer.appendChild(buyLicenseButton);

		banner.appendChild(infoText);
		banner.appendChild(remainingDaysContainer);
		const main = document.querySelector("main");
		if(main){
			main.prepend(banner);
		}
	});

	window.addEventListener("ugm-logged-out", () => {
		const banner = document.querySelector('.license-banner');
		if(banner) {
			banner.remove();
		}
	});
};

setTimeout(setupLicenseBanner, 0);
