import type { TranslationMap } from "./";

const fr:TranslationMap = {
	mvet: {
		myMvet: "<b>My</b> mVet",
		goToShop: "Vers la boutique",
		goToLibrary: "Go to Library",
		goToAccount: "Go to Account",
		yourOrders: "Your Orders"
	},
	home: "Page d'accueil",
	index: "Table des matières",
	announcements: "Announcements",
	delete: "Supprimer",
	save: "Sauvegarder",
	download: "Télécharger",
	editTask: "Résoudre l’exercice",
	title: "Titre",
	addNote: "Créer une note",
	files: "Fichiers",
	perFile: "par fichier",
	supportedFiles: "Formats de fichiers supportés",
	login: "S'inscrire",
	logout: "Se désinscrire",
	starlevel: "À quel niveau souhaites-tu travailler le chapitre ?",
	lastVisitedPages: "Dernières pages consultées:",
	author: "Auteur",
	upload: "Télécharger fichier",
	uploadError: "Malheureusement, le fichier sélectionné n'a pas pu être enregistré, il est peut-être trop volumineux.",
	saveCorrection: "Save",
	submitCorrection: "Soumettre pour correction",
	correctionSubmitted: "Correction soumise",
	correctionSubmittedModal: "Le devoir a été soumis pour correction.",
	allowExternalEmbeds: "Autorisation à télécharger des contenus externes",
	editLocked: "Il n'est donc pas possible de poursuivre le traitement pour le moment.",
	//optout
	darkMode: "Mode sombre",
	page_view_history: "Dernières entrées consultées",
	LANG: "Réglage de la langue",
	local_only_ugm_notes: "Notes locales",
	user_star_level: "Niveau de difficulté général",
	star_data: "Niveaux de difficulté enregistrés",
	local_only_ugm_correction: "les données d'entrée de vos exercices stockées localement",
	//dynamic Pages
	glossary: "Glossaire (Collection)",
	//Contenttypes
	//embeds / privacy
	onlyAllowEmbedsForLoggedInUsers: "Pour utiliser ce contenu interactif, veuillez vous connecter.",
	externalEmbed: "Contenu externe, cliquez ici pour continuer",
	externalEmbedModal:`<p>À cet endroit, vous trouverez un contenu externe. Si vous activez de tels contenus, des données personnelles peuvent être transmises à des plateformes tierces. </p><p>Pour en savoir plus, consultez notre <a href="./datenschutz/" target="_blank">déclaration de protection des données</a>.</p>`,
	accept: "Accepter",
	cancel: "Annuler",
	privacyContainer: "Vous trouverez ici les paramètres que vous avez actuellement enregistrés",
	//ugm login fields
	registerText: "Vous n'avez pas encore d'accès pour votre classe ? Inscrivez-vous ici !",
	registerTextEnforced: "Vous n'avez pas encore d'accès ? Inscrivez-vous ici !",
	registerTextGeo: "Toutes les autres régions : Vous n'avez pas encore d'accès pour votre/vos classe(s) ? Veuillez vous inscrire ici !",
	userName: "Nom d'utilisateur",
	password: "Mot de passe",
	loginInfoUsername: "Als Lehrperson melden Sie sich mit Ihrer E-Mail-Adresse an.<br /> Als Schülerin oder Schüler melden Sie sich mit Ihrer Kürzel im Format \"xxxx-farbetier\" an.",
	loginInfoUsername365: "<p>Ostbelgien: Veuillez utiliser le bouton \"Continuer avec Office 365\" ci-dessous.</p><p>Toutes les autres régions de l'Euregio Meuse-Rhin : </p><ul><li><p>En tant qu'enseignant, connectez-vous avec votre e-mail de service. </p></li><li><p>Les élèves utilisent votre abréviation au format \"xxxx-couleuranimal\"</p></li></ul><p></p>",
	loginInfoPassword: `Si vous êtes un élève et que vous avez oublié votre mot de passe, veuillez vous adresser à l'enseignant responsable. Celui-ci peut réinitialiser votre mot de passe et vous communiquer votre mot de passe temporaire. </br></br>Si vous êtes enseignant et que vous avez oublié votre mot de passe,`,
	loginInfoPasswordLink: "Réinitialiser le mot de passe",
	loginInfoPassword365: (link:string) => `<p>Pour Ostbelgien : veuillez réinitialiser votre mot de passe via MS Office 365, le cas échéant.&nbsp;</p>
	<p>Toutes les autres régions de l'Euregio Meuse-Rhin : &nbsp;</p>
	<ul>
		<li>Si vous êtes enseignant et que vous avez oublié votre mot de passe, veuillez cliquer sur ${link}.</li>
		<li>Si vous êtes un élève et que vous avez oublié votre mot de passe, veuillez vous adresser à votre enseignant. Celui-ci peut réinitialiser votre mot de passe et vous communiquer un mot de passe temporaire.</li>
	</ul>`,
	login365Label: "Continuer avec Office 365",
	login365Hint: "Remarque : dans Ostbelgien, aucun enregistrement n'est nécessaire, cliquez directement sur \"Continuer avec Office 365\".",
	invalidOIDCEmailTitle: "Email invalide",
	invalidOIDCEmailBody: "Veuillez utiliser une adresse e-mail officielle de votre école."
};
export default fr;
