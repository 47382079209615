import { TCourse } from "@digitale-lernwelten/ugm-client-lib";
import configuration from "../../../configuration";
import { showModal } from "../../modal/modal";
import { hideOverlay } from "../../overlay/overlay";
import { getCourse, getCourseList, isTeacher, getUser } from "../user-data";
import { userSetActiveCourse, ugmLogout } from "../user-group-management";
import { initMessageDom } from "../messages/messages-ui";
import { t } from "../../i18n";

import "./sidebar.css";
import { allowUseOfEmbeds } from "../../privacy";

const getLogoutButton = () : HTMLElement => {
	const ret = document.createElement("button");
	const user = getUser();
	ret.classList.add("link-logout");
	ret.innerText = `${user.loginName} abmelden`;
	ret.onclick = e => {
		e.preventDefault();
		e.stopPropagation();
		ugmLogout();
		hideOverlay();
	};
	return ret;
};

const getCourseSelection = async (thiscourse:TCourse | null): Promise<HTMLElement> => {
	const ret = document.createElement("course-select");

	let courses:TCourse[] = [];
	courses = await getCourseList();

	ret.innerHTML = courses.map((course:TCourse) => {
		const active = course.id === thiscourse?.id ? "active" :"";
		return `<course-option class="course-selection-entry ${active}" course-id="${course.id}">${course.name}</course-option>`;
	}).join("");

	Array.from(ret.querySelectorAll(".course-selection-entry")).forEach(entry => {
		const id = entry.getAttribute("course-id");
		entry.addEventListener("click", e => {
			e.preventDefault();
			const course = courses.find(a => a.id === id);
			if(!course){return;}
			userSetActiveCourse(course);
			if(configuration.courseBasedAllowanceOfExternalEmbeds){
				if(course.allowExternalEmbeds){
					allowUseOfEmbeds(true);
				} else {
					allowUseOfEmbeds(false);
				}
			}
			const detail = { course };
			window.dispatchEvent(new CustomEvent("ugm-course-changed", {detail}));
			hideOverlay();
		});
	});

	return ret;
};

const showTeacherSidebar = async () => {
	const thiscourse:TCourse | null = getCourse();
	const messageUrl = `${configuration.ugmEndpoint}?action=send-message&courseId=${thiscourse?.id || "0"}`;
	const user = getUser();

	const linkAdmin = (configuration.target !== "geografie") || (user.loginType === "local")
		? `<a class="link-admin" href="${configuration.ugmEndpoint}">Klassenverwaltung</a>`
		: "";
	const linkTask = configuration.target !== "geografie"
		? `<a class="link-task" href="${configuration.ugmEndpoint}corrections">Aufgaben korrigieren</a>`
		: "";
	const linkMessage = configuration.target !== "geografie"
		? `<a class="link-message" href="${messageUrl}">Nachricht senden</a>`
		: "";
	const linkInstitutionModal = user.institution && user.institution.email
		? `<a class="link-institution" href="#">Rechnungsanschrift bearbeiten</a>`
		: "";

	const modal = showModal(`
		<div class="user-options">
			<h3>Klassenliste</h3>
			<div class="teacher-courses"></div>
			<div class="user-links">
				${linkAdmin}
				${linkTask}
				${linkMessage}
				${linkInstitutionModal}
			</div>
		</div>
		`, "sidebar");
	const teacherCourse = modal.querySelector<HTMLElement>(".teacher-courses");
	if(teacherCourse){
		teacherCourse.append(await getCourseSelection(thiscourse));
	}
	const institutionModal = modal.querySelector<HTMLAnchorElement>(".link-institution");
	if(institutionModal){
		institutionModal.onclick = () => {
			showModal("<license-modal></license-modal>", "wide-box");
		};
	}
	const links = modal.querySelector<HTMLElement>(".user-links");
	links?.append(getSettingsButton());
	links?.append(getLogoutButton());
};

const getSettingsButton = () => {
	const button = document.createElement("button");
	button.classList.add("link-settings");
	button.innerText = "Nutzereinstellungen";
	button.onclick = () => showModal("<user-settings-modal></user-settings-modal>");
	return button;
};

const showStudentSidebar = () => {
	const modal = showModal(
		`
		<div class="user-options">
			<div class="student-messages">
				<div class="user-links"></div>
				<h3>Nachrichten</h3>
				<msg-container></msg-container>
			</div>
		</div>
		`,
		"sidebar"
	);
	const options = modal.querySelector<HTMLElement>(".user-links");
	options?.append(getLogoutButton());
	options?.append(getSettingsButton());
	initMessageDom();
};

const showMvetSidebar = () => {
	const modal = showModal(`
		<div class="user-options mvet-sidebar">
			<h3>${t().mvet.myMvet}</h3>
			<a href="${configuration.mvetShopUrl}/">${t().home}</a>
			<a href="${configuration.mvetShopUrl}/product">${t().mvet.goToShop}</a>
			<a href="${configuration.mvetShopUrl}/library">${t().mvet.goToLibrary}</a>
			<a href="${configuration.mvetShopUrl}/user-profile">${t().mvet.goToAccount}</a>
			<a href="${configuration.mvetShopUrl}/user-profile/orders">${t().mvet.yourOrders}</a>
			<a href="${configuration.mvetShopUrl}/announcements">${t().announcements}</a>
			<a href="${configuration.mvetShopUrl}/" class="logout">${t().logout}</a>
		</div>
		`, "sidebar");

	modal.querySelectorAll<HTMLAnchorElement>("a.logout").forEach(a => {
		a.onclick = async (e) => {
			e.preventDefault();
			await ugmLogout();
			document.location = a.href;
		};
	});
};

export const showSidebar = async () => {
	switch(configuration.userSidebar){
	case "mvet":
		showMvetSidebar();
		break;
	default: {
		if(isTeacher()){
			showTeacherSidebar();
		} else {
			showStudentSidebar();
		}
		break;
	}
	}
};
