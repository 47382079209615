import {customElement} from "lit/decorators.js";
import {css, html, LitElement} from "lit";
import { getUser, isTeacher } from "../../user-group-management";

@customElement("user-settings-modal")
export class UserSettingsModal extends LitElement {
	static styles = css`
		a {
			text-decoration: none;
			color: var(--dlw-blue);
		}
	`;

	render() {
		const user = getUser();
		if(!user){
			return html`<p>Bitte logge dich vorher ein.</p>`;
		}
		const userExportText = html`Klicke <a target="_blank" href="/api/export/user-data.json">hier</a> um all deine Daten zu exportieren.`;
		const userDeleteText = isTeacher()
			? html`Um Ihren Account und alle dazugehörigen Daten zu löschen, senden Sie uns bitte eine E-Mail an: <a href="mailto:info@dilewe.de">info@dilewe.de</a>`
			: html`Um deinen Account und alle dazugehörigen Daten zu löschen, gebe bitte deiner Lehrkraft bescheid.`;
		return html`
			<h4>Nutzereinstellungen</h4>
			<fancy-text-input readonly value="${user.loginName}" placeholder="Benutzername"></fancy-text-input>
			<hr/>
			<p>${userExportText}</p>
			<hr/>
			<p>${userDeleteText}</p>
		`;
	}
}
