import type { TranslationMap } from "./";

const nl:TranslationMap = {
	mvet: {
		myMvet: "<b>My</b> mVet",
		goToShop: "Naar de winkel",
		goToLibrary: "Go to Library",
		goToAccount: "Go to Account",
		yourOrders: "Your Orders"
	},
	home: "Startpagina",
	index: "Inhoudsopgave",
	announcements: "Announcements",
	delete: "Verwijder",
	save: "Red",
	download: "Download",
	editTask: "Opdracht bewerken",
	title: "Titel",
	addNote: "Notitie maken",
	files: "Bestanden",
	perFile: "per bestand",
	supportedFiles: "Ondersteunde bestandsformaten",
	login: "Aanmelden",
	logout: "Afmelden",
	starlevel: "Op welk niveau wilt u het hoofdstuk uitwerken?",
	lastVisitedPages: "Laatst bezochte pagina’s:",
	author: "Auteur",
	upload: "Bestand uploaden",
	uploadError: "Helaas kon het geselecteerde bestand niet worden opgeslagen, misschien is het te groot.",
	submitCorrection: "Indienen voor correctie",
	saveCorrection: "Save",
	correctionSubmitted: "Correctie ingediend",
	correctionSubmittedModal: "De taak werd ter correctie voorgelegd.",
	editLocked: "Verdere verwerking is daarom momenteel niet mogelijk.",
	//optout
	allowExternalEmbeds: "Toestemming om externe inhoud te laden",
	darkMode: "Darkmode instelling",
	page_view_history: "Laatst bekeken gegevens",
	LANG: "Taalinstelling",
	local_only_ugm_notes: "Lokale notities",
	user_star_level: "Globaal moeilijkheidsniveau",
	star_data: "Opgeslagen differentiaties",
	local_only_ugm_correction: "lokaal opgeslagen invoer van je oefeningen",
	//dynamic Pages
	glossary: "Woordenlijst (Collectie)",
	//Contenttypes
	//embeds / privacy
	onlyAllowEmbedsForLoggedInUsers: "Om deze interactieve inhoud te gebruiken, logt u in.",
	externalEmbed: "Externe inhoud, klik hier om verder te gaan",
	externalEmbedModal:`<p>Op dit punt vindt u externe inhoud. Als u dergelijke inhoud inschakelt, kunnen persoonlijke gegevens worden verzonden naar platforms van derden. </p><p>Meer hierover in onze <a href="./datenschutz/" target="_blank">verklaring over gegevensbescherming</a>.</p>`,
	accept: "Accepteren",
	cancel: "Annuleren",
	privacyContainer: "Hier vindt u uw momenteel opgeslagen instellingen",
	//ugm login fields
	registerText: "Heb je nog geen toegang voor je klas? Registreer je dan hier!",
	registerTextEnforced: "Heb je nog geen account? Registreer je dan hier!",
	registerTextGeo: "Alle andere regio's: Heb je nog geen toegang voor je klas(sen)? Registreer je dan hier!",
	userName: "Gebruikersnaam",
	password: "Wachtwoord",
	loginInfoUsername: "Als Lehrperson melden Sie sich mit Ihrer E-Mail-Adresse an.<br /> Als Schülerin oder Schüler melden Sie sich mit Ihrer Kürzel im Format \"xxxx-farbetier\" an.",
	loginInfoUsername365: "<p>Ostbelgien: Gebruik de knop \"Doorgaan met Office 365\" hieronder.</p><p>Alle andere regio's van de Euregio Maas-Rijn: </p><ul><li><p>Log als leerkracht in met je werkmail. </p></li><li><p>Leerlingen gebruiken hun afkorting in het formaat \"xxxx-kleurdier\".</p></li></ul><p></p>",
	loginInfoPassword: `Als je als leerling je wachtwoord bent vergeten, neem dan contact op met de verantwoordelijke docent. Zij kunnen je wachtwoord resetten en je een tijdelijk wachtwoord geven.</br></br>Als u als docent uw wachtwoord bent vergeten,`,
	loginInfoPasswordLink: "Wachtwoord resetten",
	loginInfoPassword365: (link:string) => `<p>Voor Ostbelgien: Reset indien nodig je wachtwoord via MS Office 365.&nbsp;</p>
	<p>Alle andere regio's van de Euregio Maas-Rijn:&nbsp;</p>
	<ul>
		<li>Als je een leerkracht bent en je wachtwoord bent vergeten, Klik op ${link}.</li>
		<li>Als je een leerling bent en je wachtwoord bent vergeten, neem dan contact op met je docent. Zij kunnen je wachtwoord resetten en je een tijdelijk wachtwoord geven</li>
	</ul>`,
	login365Label: "Doorgaan met Office 365",
	login365Hint: "Opmerking: in Oost-België is geen registratie vereist, klik direct op \"Doorgaan met Office 365\".",
	invalidOIDCEmailTitle: "Ongeldig e-mail",
	invalidOIDCEmailBody: "Gebruik een officieel e-mailadres van uw school."
};
export default nl;
